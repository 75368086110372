import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Class101UIProvider, AnchorButton } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "class101uiprovider"
    }}>{`Class101UIProvider`}</h1>
    <p>{`The Class101UIProvider component is used to globally share settings to be used in Class101 UI.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Class101UIProvider} mdxType="Props" />
    <h2 {...{
      "id": "with-linkcomponent"
    }}>{`With LinkComponent`}</h2>
    <Playground __position={1} __code={'() => {\n  const handleAchoreClick = () => {\n    if (window !== undefined) {\n      window.alert(\'Custom Link\')\n    }\n  }\n  const customLink = ({ to, children }) => {\n    return (\n      <a href={to} onClick={handleAchoreClick}>\n        {children}\n      </a>\n    )\n  }\n  return (\n    <Class101UIProvider linkComponent={customLink}>\n      <AnchorButton to=\"/\">Click me</AnchorButton>\n    </Class101UIProvider>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Class101UIProvider,
      AnchorButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const handleAchoreClick = () => {
          if (window !== undefined) {
            window.alert('Custom Link');
          }
        };

        const customLink = ({
          to,
          children
        }) => {
          return <a href={to} onClick={handleAchoreClick}>
          {children}
        </a>;
        };

        return <Class101UIProvider linkComponent={customLink} mdxType="Class101UIProvider">
        <AnchorButton to="/" mdxType="AnchorButton">Click me</AnchorButton>
      </Class101UIProvider>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      